import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-16 pb-32 bg-dark-100/30 stats z-50 text-2xl font-semibold mt-20">
      <ul className="container mx-auto flex flex-col lg:flex-row">
        <li>
          <Link to="/" className="link hover:text-main hover:underline">
            Home
          </Link>
        </li>
        <li>
          <Link to="/buy" className="link hover:text-main hover:underline">
            Buy Robux
          </Link>
        </li>
        <li>
          <Link to="/faq" className="link hover:text-main hover:underline">
            FAQ
          </Link>
        </li>
        <li>
          <Link to="/account" className="link hover:text-main hover:underline">
            My Account
          </Link>
        </li>
      </ul>
      <ul className="container mx-auto flex flex-col-reverse items-center font-normal text-lg mt-5 justify-between">
        <div className="flex items-center gap-5 text-center">
          <li>
            <Link to="/" className="link">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/buy" className="link">
              Terms and conditions
            </Link>
          </li>
          <li>
            <Link to="/account" className="link">
              Refund Policy
            </Link>
          </li>
        </div>
        <div className="flex items-center gap-5">
          <i className="fab fa-discord"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-instagram"></i>
        </div>
      </ul>
    </footer>
  );
};

export default Footer;
