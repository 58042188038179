import React from "react";

const PurchaseHistory = () => {
  return (
    <div>
      <h2 className="text-4xl font-semibold">Purchase History</h2>
      <div className="overflow-x-scroll">
        <table className="flex flex-col mt-10 min-w-max">
          <thead className="mb-3">
            <tr className="grid grid-cols-5 text-left px-10">
              <th>Order Number</th>
              <th>Date</th>
              <th>R$</th>
              <th>Quantity</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="flex flex-col gap-4">
            <tr className="bg-dark-100 rounded-2xl grid grid-cols-5 px-10 py-5">
              <td>1</td>
              <td>01/01/2022</td>
              <td>R$1000</td>
              <td>8$</td>
              <td className="bg-yellow-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                Pending
              </td>
            </tr>
            <tr className="bg-dark-100 rounded-2xl grid grid-cols-5 px-10 py-5">
              <td>1</td>
              <td>01/01/2022</td>
              <td>R$1000</td>
              <td>8$</td>
              <td className="bg-green-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                completed
              </td>
            </tr>
            <tr className="bg-dark-100 rounded-2xl grid grid-cols-5 px-10 py-5">
              <td>1</td>
              <td>01/01/2022</td>
              <td>R$1000</td>
              <td>8$</td>
              <td className="bg-red-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                failed
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseHistory;
