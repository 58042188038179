import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReactSlider from "react-slider";
import useSWR from "swr";
import toast from "react-hot-toast";

// Constants
const MIN_ROBUX_AMOUNT = 100;
const MAX_ROBUX_AMOUNT = 10000;

// SWR fetcher
const rateFetcher = (...args) => fetch(...args).then((res) => res.json());

const SelectAmount = ({ handleNext, purchaseDataChange }) => {
  const [sliderValue, setSliderValue] = useState(MIN_ROBUX_AMOUNT);
  const [spendDollarAmount, setSpendDollarAmount] = useState();
  const [spendRobuxAmount, setSpendRobuxAmount] = useState(MIN_ROBUX_AMOUNT);
  const [ratePerThousand, setRatePerThousand] = useState();

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/rate`,
    rateFetcher
  );

  useEffect(() => {
    if (data) {
      setRatePerThousand(data.Data.rate);
      setSpendDollarAmount(
        ((MIN_ROBUX_AMOUNT / 1000) * data.Data.rate).toFixed(2)
      );
    }
  }, [data]);

  const setValues = (rbx, dollars) => {
    setSliderValue(rbx);
    setSpendRobuxAmount(rbx);
    setSpendDollarAmount(dollars);
    purchaseDataChange("purchase", {
      robux: Number(rbx),
      usd: Number(dollars),
    });
  };

  const sliderChange = (e) => {
    let rbx = Number(e);
    let dollarAmount = ((rbx / 1000) * ratePerThousand).toFixed(2);
    setValues(rbx, dollarAmount);
  };

  const rbxAmountChange = (e) => {
    let rbx = Number(e.target.value).toFixed(0);
    if (rbx > 10000 || rbx < 0) return;
    let dollarAmount = ((rbx / 1000) * ratePerThousand).toFixed(2);
    setValues(rbx, dollarAmount);
  };

  const dollarAmountChange = (e) => {
    let dollars = Number(e.target.value);
    if (dollars > 40 || dollars < 0) return;
    let rbxAmount = ((dollars / ratePerThousand) * 1000).toFixed(0);
    setValues(rbxAmount, dollars);
  };

  // Set the default values of rbx and dollars
  useEffect(() => {
    setValues(100, 0.42);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form className="">
        <div className="flex items-center gap-4">
          <h1 className="text-4xl font-semibold">Select an amount </h1>
          <h2 className="text-white/50 text-xl text-center justify-items-center">
            <span className="text-white/20">|</span> ${ratePerThousand} = 1000
            Robux
          </h2>
        </div>
        <div className="mb-5 h-32 w-full xl:w-3/4 relative mx-auto mt-10 flex flex-col">
          <div className="items-center  mx-auto md:gap-16 font-bold !text-5xl w-full justify-center grid grid-cols-1 md:grid-cols-3">
            <div className="">
              <p className="text-lg text-center md:text-left font-light opacity-75 mb-2">
                Recieve
              </p>
              <div className="flex relative justify-center text-center md:text-left">
                <span className="absolute left-0 top-5 text-main text-3xl">
                  R$
                </span>
                <input
                  type="number"
                  value={spendRobuxAmount}
                  onChange={rbxAmountChange}
                  max={10000}
                  className="input indent-10 w-fit max-w-[200px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
              </div>
              <p className="text-center opacity-75">
                Max: {MAX_ROBUX_AMOUNT.toLocaleString()}
              </p>
              <p className="text-center opacity-75">Min: {MIN_ROBUX_AMOUNT}</p>
            </div>

            <div className="flex items-center justify-center flex-col">
              <i className="fal fa-exchange"></i>
            </div>

            <div className="">
              <p className="text-lg text-center md:text-left font-light opacity-75 mb-2">
                Spend
              </p>
              <div className="flex relative justify-center text-center md:text-left">
                <span className="absolute left-0 top-5 text-3xl text-main">
                  $
                </span>
                <input
                  type="text"
                  value={spendDollarAmount}
                  onChange={dollarAmountChange}
                  className="input indent-10 w-fit max-w-[200px]"
                />
              </div>
              <p className="text-center opacity-75">
                Max: {Number(ratePerThousand) * (MAX_ROBUX_AMOUNT / 1000)}
              </p>
              <p className="text-center opacity-75">
                Min:{" "}
                {(Number(ratePerThousand) * (MIN_ROBUX_AMOUNT / 1000)).toFixed(
                  2
                )}
              </p>
            </div>
          </div>
          <div className="mt-12">
            <ReactSlider
              value={sliderValue}
              max={MAX_ROBUX_AMOUNT}
              min={MIN_ROBUX_AMOUNT}
              step={10}
              onChange={sliderChange}
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
            />
            <div className="flex items-center justify-between mt-5">
              <div>
                <p>{MIN_ROBUX_AMOUNT}R$</p>
              </div>
              <div className="flex gap-2">
                <p>{sliderValue.toLocaleString()}</p>
                <p className="text-main">R$</p>
              </div>
              <div>
                <p>{MAX_ROBUX_AMOUNT.toLocaleString()}R$</p>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center flex-col text-center">
            <p>
              Max per order: <span className="text-main">R$</span>{" "}
              {MAX_ROBUX_AMOUNT.toLocaleString()}
            </p>
            <p>
              Min per order: <span className="text-main">R$</span>{" "}
              {MIN_ROBUX_AMOUNT}
            </p>
          </div> */}
        </div>
        <div className="flex gap-4 mt-[400px] lg:mt-36 justify-end">
          <button
            type="button"
            className="cta !py-2 w-32 text-xl text-center"
            onClick={() => {
              if (Number(spendRobuxAmount) < MIN_ROBUX_AMOUNT) {
                return toast.error(
                  `You must buy more than ${MIN_ROBUX_AMOUNT} robux`
                );
              } else if (
                Number(spendRobuxAmount) > MAX_ROBUX_AMOUNT ||
                Number(spendDollarAmount) >
                  ratePerThousand * (MAX_ROBUX_AMOUNT / 1000)
              ) {
                return toast.error(
                  `You can only buy at max ${MAX_ROBUX_AMOUNT} robux`
                );
              }
              handleNext();
            }}
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default SelectAmount;
