import { Route, Routes, useLocation } from "react-router-dom";

// -- components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// -- pages
import Home from "./pages/Home";
import Account from "./pages/Account";
import VerifyFailed from "./pages/VerifyFailed";

// -- other
import ScrollToTop from "./components/ScrollTop";
import { AnimatePresence } from "framer-motion";
import Faq from "./pages/Faq";
import Buy from "./pages/Buy";
import { Toaster } from "react-hot-toast";
import NextTopLoader from "nextjs-toploader";
import { AuthContextProvider } from "./context/authContext";
import { AuthModalContextProvider } from "./context/authModalContext";
import AuthModal from "./components/Auth/Modal";

const App = () => {
  const location = useLocation();
  return (
    <>
      <AuthContextProvider>
        <AuthModalContextProvider>
          <ScrollToTop />
          <Navbar />
          <AuthModal />
          <AnimatePresence mode="wait">
            <section className="pt-20">
              <Routes location={location} key={location.pathname}>
                {/* <Route path="*" element={<Navigate to="/" />} /> */}
                <Route path="/" element={<Home />} />
                <Route path="/buy" element={<Buy />} />
                <Route path="/account" element={<Account />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/verify/failed" element={<VerifyFailed />} />
              </Routes>
            </section>
          </AnimatePresence>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              style: {
                border: "1px solid #30DA30",
                padding: "16px",
                color: "#30DA30",
                background: "#212121",
              },
              success: {
                iconTheme: {
                  primary: "#30DA30",
                  secondary: "#FFFAEE",
                },
              },
            }}
          />
          <NextTopLoader
            color="#30DA30"
            showSpinner={false}
            shadow="0 0 10px #30DA30,0 0 5px #30DA30"
          />
          <Footer />
        </AuthModalContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;
