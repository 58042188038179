import { useAuth } from "../context/authContext";

export function withAuth(Component) {
  return function WithAuth(props) {
    const { isSignedIn, loading } = useAuth();

    // Redirect to login page if not authenticated
    if (!loading && !isSignedIn) {
      window.location.replace("/");
      return null;
    }

    return <Component {...props} />;
  };
}
