import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({ user: null });

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const navigate = useNavigate();

  const register = async (
    email,
    password,
    confirmPassword,
    setError,
    setMessage,
    setRequestLoading,
    captchaToken = null
  ) => {
    try {
      setError(false);
      setMessage(false);
      setRequestLoading(true);
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
            confirmPassword,
            //captchaToken,
          }),
        }
      );
      const data = await req.json();
      setRequestLoading(false);
      if (req.status === 201) {
        setError(false);
        setMessage(data.Message);
      } else {
        setError(data.Message);
      }
    } catch (error) {
      console.error(error);
      setMessage(false);
      setError("An unknown error occured.");
    } finally {
      setRequestLoading(false);
    }
  };

  const login = async (
    email,
    password,
    setError,
    setRequestLoading,
    ref = null,
    captchaToken = null
  ) => {
    try {
      setError(false);
      setRequestLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const data = await res.json();
      setRequestLoading(false);
      if (res.status === 200) {
        setError(false);
        toast.success("Successfully logged in!");
        setTimeout(() => {
          window.location.replace("/buy");
        }, 1000);
      } else {
        setError(data.Message);
      }
    } catch (error) {
      setError("An unknown error occured.");
    } finally {
      setRequestLoading(false);
    }
  };

  const logout = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/logout`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        setUser(null);
        toast.success("Logged out");
        validateLogin();
      } else {
        toast.error(data.Message);
      }
    } catch (error) {
      toast.error("Error during logout");
    }
  };

  const validateLogin = async (redirectIfNotLoggedIn) => {
    setLoading(true);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://auth.${process.env.REACT_APP_MAIN_DOMAIN}/validate-login`,
        {
          credentials: "include",
        }
      );
      setLoading(false);
      if (req.status === 200) {
        setSignedIn(true);
      } else {
        setSignedIn(false);
        if (redirectIfNotLoggedIn) {
          navigate(redirectIfNotLoggedIn);
        }
      }
    } catch (error) {
      setSignedIn(false);
    }
  };

  const fetchUser = async () => {
    setLoading(true);
    try {
      let data;
      if (process.env.NODE_ENV === "production") {
        const res = await fetch("https://auth.buxloot.com/user/data", {
          method: "GET",
          credentials: "include",
        });

        if (!res.ok) {
          setLoading(false);
          setUser(null);
          return;
        }

        data = (await res.json())["Data"];
      } else if (process.env.NODE_ENV === "development") {
        data = {
          id: "65",
          userId: "1",
          balance: 5000,
          creationDate: "2023-10-15T23:25:07.291Z",
          referredFrom: null,
          totalEarned: 0,
          userRefers: "0",
          completedOffers: 1,
          role: "USER",
          username: "Roblox",
          thumbnailUrl:
            "https://api.buxloot.com/rbx/thumbnail/1/avatar-headshot",
        };
      }
      setUser(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initial page load (ex: Not using the navbar)
    // fetchUser();
    validateLogin();
  }, []);

  const context = {
    user,
    //fetchUser,
    loading,
    // isSignedIn: user !== null,
    isSignedIn: signedIn,
    login,
    register,
    logout,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
