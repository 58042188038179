import React, { useState } from "react";
import { useAuth } from "../../context/authContext";

export default function Login({ toggleForgotPassTab }) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();

  async function sendLogin() {
    setError("");
    try {
      if (!email || !password) {
        return setError("Please emter email and password.");
      }
      await login(email, password, setError, setIsLoading);
    } catch (error) {
      setIsLoading(false);
      setError("Unexpected error occured.");
    }
  }

  function updateEmail(e) {
    setEmail(e.target.value);
  }
  function updatePassword(e) {
    setPassword(e.target.value);
  }

  const handleClickShowPassword = () => setShowPassword((state) => !state);

  return (
    <div className="flex flex-col md:gap-5 gap-3">
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> E-mail
        </label>
        <input
          type="text"
          onChange={updateEmail}
          className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <div className="flex flex-col pb-2">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> Password
        </label>
        <div className="flex flex-col gap-1">
          <input
            type={showPassword ? "text" : "password"}
            onChange={updatePassword}
            className="!bg-dark rounded-md w-full !border-none placeholder-white/50 border-white/5 text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
          />
          <div className="flex">
            <button
              onClick={handleClickShowPassword}
              className="text-white hover:text-white/75 duration-200 pl-2"
            >
              {showPassword ? "Hide password" : "Show password"}
            </button>
          </div>
        </div>
      </div>
      <button className="cta text-lg" onClick={sendLogin}>
        Login
      </button>
      {isLoading && (
        <div>
          <p className="text-white">Loading...</p>
        </div>
      )}
      {error !== "" && (
        <div>
          <p className="text-red-500">{error}</p>
        </div>
      )}
      <div className="flex">
        <button
          onClick={toggleForgotPassTab}
          className="text-end text-main/75 underline underline-offset-2"
        >
          Forgot your password?
        </button>
      </div>
    </div>
  );
}
