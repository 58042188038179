import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useState } from "react";

const fetchRates = async (setRateInfo) => {
  try {
    const rates = await fetch(
      `${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/rate`
    );
    if (rates.status !== 200) return;

    const data = (await rates.json())["Data"];
    setRateInfo(data);
  } catch {
    console.error("Could not fetch current rates, using default");
  }
};

const Faq = ({ descText }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [rateInfo, setRateInfo] = useState({
    rate: 4.2,
    percentSaved: 66.4,
    amountSaved: 8.3,
  });

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    fetchRates(setRateInfo);
  }, []);

  const accordionItems = [
    {
      title: "Is this safe?",
      content:
        "Yes, our services are completely safe and secure. We ensure that all transactions are processed using ONLY trusted big-name companies like Stripe and Cryptomus. The Robux that our site offers is confirmed non-pois robux, and is sold DIRECTLY from game developers who are unable to use the dev-ex system.",
    },
    {
      title: "What are the rates?",
      content: `Our current rate is $${rateInfo.rate} USD for 1,000 Robux. This means we sell at approximately ${rateInfo.percentSaved}% less than Roblox, saving you $${rateInfo.amountSaved} per 1,000 Robux when you shop with us!`,
    },
    {
      title: "When will my robux arrive?",
      content:
        "The ROBUX is delivered instantly, but due to ROBLOX's pending funds system, it will take roughly 3-5 to receive your ROBUX.",
    },
    {
      title: "Can I order above the maximum allowed amount?",
      content:
        "Absolutely! The site has a cap of 10,000 Robux per order in order to provide everybody with the ability to purchase Robux and keep high stock. If you want to order more than this, you can reach out to a staff member on our Discord and they will accommodate you.",
    },
    {
      title: "I need support!",
      content:
        "No worries! Our support is available 24/7 through Discord. Open a ticket and a staff/support member will be with you shortly. If you prefer email, you can email support@cheapestrobux.com and support will reach out to you when available.",
    },
    {
      title: "How do I get started?",
      content:
        "To get started, simply register on our website, choose the amount of ROBUX you want, and follow the easy steps to complete your order. If you need any help, our customer support is available 24/7 through Discord or you can email support@cheapestrobux.com and support will reach out to you when available..",
    },
  ];

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="container mx-auto"
    >
      <h1>
        Frequently Asked <span className="text-main ">questions</span>
      </h1>

      <h2 className="w-3/4 my-10 text-lg">
        Is your question not answered here? You can{" "}
        <a
          href={`${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/discord`}
          target="_blank"
          rel="noreferrer"
          className="underline underline-offset-2 text-main hover:cursor-pointer hover:text-main/75 duration-100"
        >
          join our discord
        </a>{" "}
        and ask it there. Members of our community, or a staff member will
        answer your question!
      </h2>

      <div>
        {accordionItems.map((item, index) => (
          <div key={index}>
            <button
              onClick={() => handleClick(index)}
              className={`text-2xl w-full text-left px-10 py-8 font-medium border-t-[3px] border-dark-100 ${
                index === activeIndex ? "text-main" : ""
              }`}
            >
              {item.title}
            </button>
            <AnimatePresence initial={false}>
              {index === activeIndex && (
                <motion.div
                  key="content"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="px-10 pb-6"
                >
                  <p className="text-lg font-normal w-3/4">{item.content}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Faq;
