import React, { useState } from "react";
import { useAuth } from "../../context/authContext";

const popularDomains = new Set([
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "protonmail.com",
  "live.com",
  "msn.com",
  "comcast.net",
  "me.com",
  "mac.com",
  "sbcglobal.net",
  "verizon.net",
  "att.net",
  "bellsouth.net",
  "charter.net",
  "earthlink.net",
  "hubspot.com",
  "mail.ru",
  "inbox.com",
  "rocketmail.com",
  "yahoo.co.uk",
  "btinternet.com",
  "sky.com",
  "virginmedia.com",
  "ntlworld.com",
  "talktalk.net",
  "tiscali.co.uk",
  "blueyonder.co.uk",
  "btconnect.com",
  "eircom.net",
  "eir.ie",
  "mail.bg",
  "abv.bg",
  "ukr.net",
  "email.com",
  "mailinator.com",
]);

function isEmailFromUnpopularDomain(email) {
  const emailParts = email.split("@");
  if (!isValidEmail(email)) {
    return false;
  }

  const domain = emailParts[1];
  return !popularDomains.has(domain);
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function validateRegistrationDetails(email, password, confirmPassword) {
  if (!email || !password || !confirmPassword)
    return {
      result: false,
      message: "You have not fully entered your registration information.",
    };

  // password !== confirmPassword
  if (password !== confirmPassword)
    return {
      result: false,
      message: "The passwords you entered do not match. Please try again.",
    };

  // Email checks
  if (!isValidEmail(email))
    return { result: false, message: "E-mail is not valid." };

  if (isEmailFromUnpopularDomain(email))
    return { result: false, message: "Your e-mail provider is not allowed." };

  return { result: true };
}

export default function Register() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [message, setMessage] = useState("");
  const { register } = useAuth();

  const handleClickShowPassword = () => setShowPassword((state) => !state);

  async function sendRegistration() {
    try {
      const validateDetails = validateRegistrationDetails(
        email,
        password,
        confirmPassword
      );
      if (validateDetails.result === false) {
        setError(validateDetails.message);
      } else {
        await register(
          email,
          password,
          confirmPassword,
          setError,
          setMessage,
          setIsLoading
        );
      }
    } catch (error) {
      setError("Unexpected error occured.");
    }
  }

  function updateEmail(e) {
    setEmail(e.target.value);
  }
  function updatePassword(e) {
    setPassword(e.target.value);
  }
  function updateConfirmPassword(e) {
    setConfirmPassword(e.target.value);
  }

  return (
    <div className="flex flex-col md:gap-5 gap-3">
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> E-mail
        </label>
        <input
          type="text"
          onChange={updateEmail}
          className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> Password
        </label>

        <input
          type={showPassword ? "text" : "password"}
          onChange={updatePassword}
          className="!bg-dark rounded-md w-full !border-none placeholder-white/50 border-white/5 text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> Confirm password
        </label>
        <div className="flex flex-col gap-1">
          <input
            type={showPassword ? "text" : "password"}
            onChange={updateConfirmPassword}
            className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
          />
          <div className="flex">
            <button
              onClick={handleClickShowPassword}
              className="text-white hover:text-white/75 duration-200 pl-2"
            >
              {showPassword ? "Hide password" : "Show password"}
            </button>
          </div>
        </div>
      </div>
      {message && (
        <div>
          <p className="text-white">{message}</p>
        </div>
      )}
      <button type="submit" className="cta text-lg" onClick={sendRegistration}>
        Register
      </button>
      {isLoading && (
        <div>
          <p className="text-white">Loading...</p>
        </div>
      )}
      {error !== "" && (
        <div>
          <p className="text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
}
