import { motion } from "framer-motion";
import { useRef } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

function Step4({
  handleNext,
  handleBack,
  username,
  robuxAmount,
  dollarAmount,
}) {
  // REFS
  const paymentMethodRef = useRef();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form>
        <div>
          <h2 className="text-4xl font-semibold text-center md:text-start">
            Order Overview
          </h2>
          <div className="p-7 my-5 rounded-2xl bg-dark flex items-center justify-between drop-shadow-xl ">
            <div className="space-y-5">
              <div>
                <h3 className="opacity-50 text-base">Roblox Username</h3>
                <p className="font-medium text-lg">{username}</p>
              </div>
              <div>
                <h3 className="opacity-50 text-base">Buying</h3>
                <h3 className="font-medium text-2xl">
                  <span className="text-main">R$</span>{" "}
                  {robuxAmount.toLocaleString()}
                </h3>
              </div>
              <div className="flex flex-col">
                <h3 className="opacity-50 text-base">Price</h3>
                <div className="flex gap-2">
                  <h3 className="font-medium text-2xl text-main">
                    ${" "}
                    <span className="text-white">
                      {dollarAmount.toLocaleString()}
                    </span>
                  </h3>
                  <h3 className="font-medium text-2xl">USD</h3>
                </div>
              </div>
            </div>
            <div>
              <i className="fas fa-receipt mx-10 text-8xl"></i>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-4xl font-semibold text-center md:text-start">
            How would you like to pay?
          </h2>
          <div className="p-7 my-5 rounded-2xl bg-dark flex items-center justify-between drop-shadow-xl">
            <form>
              <RadioGroup.Root
                className="flex flex-col gap-2.5"
                defaultValue="crypto"
                ref={paymentMethodRef}
                aria-label="Choose payment method"
              >
                {/* <div className="flex items-center">
                  <RadioGroup.Item
                    className="bg-white duration-200 w-[25px] h-[25px] rounded-md shadow-[0_2px_10px] shadow-blackA4 hover:bg-main focus:shadow-[0_0_0_2px] focus:shadow-black outline-none cursor-default"
                    value="stripe"
                    id="r1"
                  >
                    <RadioGroup.Indicator className="duration-200 flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-main" />
                  </RadioGroup.Item>
                  <label
                    className="text-white leading-none pl-[15px] text-xl font-medium gap-5 flex items-center"
                    htmlFor="r1"
                  >
                    Pay using Stripe
                    <span className="font-light text-lg text-white/50">
                      Apple Pay, Debit/Credit Card
                    </span>
                  </label>
                </div> */}
                <div className="flex items-center">
                  <RadioGroup.Item
                    className="bg-white w-[25px] h-[25px] duration-200 rounded-md shadow-[0_2px_10px] shadow-blackA4 hover:bg-main focus:shadow-[0_0_0_2px] focus:shadow-black outline-none cursor-default"
                    value="crypto"
                    id="r2"
                  >
                    <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-main" />
                  </RadioGroup.Item>
                  <label
                    className="text-white leading-none pl-[15px] text-xl font-medium gap-5 flex items-center"
                    htmlFor="r2"
                  >
                    Pay using Crypto
                    <span className="font-light text-lg text-white/50">
                      All Popular Cryptocurrencies
                    </span>
                  </label>
                </div>
              </RadioGroup.Root>
            </form>
          </div>
        </div>

        <div className="flex gap-4 justify-end">
          <button
            type="button"
            className="cta-cancel !py-2 w-32 text-xl text-center"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="submit"
            className="cta !py-2 w-32 text-xl text-center"
            onClick={handleNext}
          >
            Purchase
          </button>
        </div>
      </form>
    </motion.div>
  );
}

export default Step4;
