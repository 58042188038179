import { motion } from "framer-motion";

function FundsPendWarning({ handleNext, handleBack }) {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form>
        <h2 className="text-4xl font-semibold">Warning ⚠️</h2>
        <h2 className="text-2xl font-medium mt-8">
          Funds may take 5 to 7 days to pend
        </h2>
        <p className="font-normal text-xl opacity-50 mb-5">
          These funds are transferred via a Game pass purchase and it will take
          5 to 7 days to reach your account!
        </p>

        <div className="flex gap-4 justify-end">
          <button
            type="button"
            className="cta-cancel !py-2 w-32 text-xl text-center"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="submit"
            className="cta !py-2 w-32 text-xl text-center"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
}

export default FundsPendWarning;
