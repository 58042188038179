import { motion } from "framer-motion";

function Step5({ handleNext, handleBack }) {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form>
        <h2 className="text-4xl font-semibold text-center">
          Thanks for your purchase
        </h2>
        <div className="flex flex-col gap-2 text-center my-16">
          <p>Your payment was successful.</p>
          <p>
            The purchased Robux will arrive in your account within 5 to 7 days
          </p>
          <p>
            Leave a review on our{" "}
            <a
              href="http://"
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              trustpilot
            </a>
            ! <br />
            And if any issues occur, please contact us at
            admin@cheapestrobux.com.
          </p>
        </div>
        <button
          type="submit"
          className="cta !py-2 px-16 text-xl text-center mx-auto flex"
          onClick={handleBack}
        >
          My Order Overview
        </button>
      </form>
    </motion.div>
  );
}

export default Step5;
