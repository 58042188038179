import { useState } from "react";
import Backdrop from "./Backdrop";
import { motion } from "framer-motion";

const dropInOut = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const ChangePassword = ({ handleClose }) => {
  const [showPasswords, setShowPasswords] = useState(false);

  const handleClickShowPassword = () => setShowPasswords((state) => !state);

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="modal max-w-[500px]"
        onClick={(e) => e.stopPropagation()}
        variants={dropInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex justify-between">
          <div className="flex gap-2 items-center mb-1">
            <h3 className="text-xl font-semibold">Change Password</h3>
          </div>
          <div
            onClick={handleClose}
            className="cursor-pointer hover:text-white w-7 h-7 flex items-center justify-center"
          >
            <i className="fal fa-times text-xl"></i>
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-3">
          <input
            type={showPasswords ? "text" : "password"}
            placeholder="Old Password"
            className="w-full"
          />
          <div className="flex flex-col md:flex-row gap-5">
            <input
              type={showPasswords ? "text" : "password"}
              placeholder="New Password"
              className="w-full"
            />
            <input
              type={showPasswords ? "text" : "password"}
              placeholder="Confirm Password"
              className="w-full"
            />
          </div>
          <div className="flex justify-items-center">
            <button
              onClick={handleClickShowPassword}
              className="text-white hover:text-white/75 duration-200"
            >
              {showPasswords ? "Hide passwords" : "Show passwords"}
            </button>
          </div>
          <div className="flex flex-col md:flex-row w-full text-lg items-center font-medium gap-2 mt-5 justify-end">
            <button
              className="cta-cancel !py-2 !w-full md:!w-fit !bg-dark"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button className="cta !py-2 !w-full md:!w-fit">
              Save Changes
            </button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ChangePassword;
