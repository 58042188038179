import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import SelectAmount from "./SelectAmount";
import FundsPendWarning from "./FundsPendWarning";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

function Form() {
  const [step, setStep] = useState(1);
  const [stepsCompleted, setStepsCompleted] = useState([1]);

  const [purchaseData, setPurchaseData] = useState({
    user: {
      username: undefined,
      id: undefined,
    },
    purchase: {
      robux: undefined,
      usd: undefined,
    },
    game: {
      id: undefined,
      universeId: undefined,
      name: undefined,
    },
  });

  const handlePurchaseDataChange = (field, value) => {
    setPurchaseData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleNext = () => {
    setStepsCompleted([...stepsCompleted, step + 1]);
    setStep(step + 1);
  };

  const handleBack = () => {
    setStepsCompleted(stepsCompleted.filter((s) => s !== step));
    setStep(step - 1);
  };

  return (
    <div className="form">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {step > 2 && (
          <div className="form-progress !mt-12">
            <div
              className="form-progress-bar"
              style={{ width: `${((step - 3) / 4) * 100}%` }}
            />
            <div className="form-progress-circles">
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(3) ? "active" : ""
                }`}
              >
                1
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(4) ? "active" : ""
                }`}
              >
                2
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(5) ? "active" : ""
                }`}
              >
                3
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(6) ? "active" : ""
                }`}
              >
                4
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(7) ? "active" : ""
                }`}
              >
                5
              </div>
            </div>
          </div>
        )}
        <div className="formContent !mt-10">
          {step === 1 && (
            <SelectAmount
              handleNext={handleNext}
              purchaseDataChange={handlePurchaseDataChange}
            />
          )}
          {step === 2 && (
            <FundsPendWarning handleNext={handleNext} handleBack={handleBack} />
          )}

          {/* Select account */}
          {step === 3 && (
            <Step1
              handleNext={handleNext}
              handleBack={handleBack}
              username={purchaseData.user.username}
              purchaseDataChange={handlePurchaseDataChange}
            />
          )}

          {/* Select game */}
          {step === 4 && (
            <Step2
              handleNext={handleNext}
              handleBack={handleBack}
              ID={purchaseData.user.id}
              purchaseDataChange={handlePurchaseDataChange}
            />
          )}

          {/* Create gamepass */}
          {step === 5 && (
            <Step3
              handleNext={handleNext}
              handleBack={handleBack}
              robuxAmount={purchaseData.purchase.robux}
              gameName={purchaseData.game.name}
              gameId={purchaseData.game.id}
              gameUniverseId={purchaseData.game.universeId}
            />
          )}

          {/* Order Confirmation step */}
          {step === 6 && (
            <Step4
              handleNext={handleNext}
              handleBack={handleBack}
              username={purchaseData.user.username}
              robuxAmount={purchaseData.purchase.robux}
              dollarAmount={purchaseData.purchase.usd}
            />
          )}

          {/* Thanks for your purchase */}
          {step === 7 && <Step5 handleBack={handleBack} />}
        </div>
      </motion.div>
    </div>
  );
}

export default Form;
