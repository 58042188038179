import React from "react";

export default function ForgotPassword() {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col">
        <label className="text-[18px] tracking-wide pl-2 pb-1 text-white/75">
          <span className="text-red-500">*</span> E-mail
        </label>
        <input
          type="text"
          id="Email"
          className="!bg-dark rounded-md !border-none placeholder-white/50 border-white/5 text-lg py-2 px-2 md:!py-2 md:px-3 outline-none bg"
        />
      </div>
      <button className="cta ">Recover</button>
    </div>
  );
}
