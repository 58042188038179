import { motion } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";

function Step3({
  handleNext,
  handleBack,
  robuxAmount,
  gameName,
  gameId,
  gameUniverseId,
}) {
  const [error, setError] = useState(null);

  const checkGamepassThenContinue = async () => {
    // Checks if user has gamepass with selected price
    setError(null);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://rbx.${process.env.REACT_APP_MAIN_DOMAIN}/check-gamepasses?price=${robuxAmount}&universeId=${gameUniverseId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!req.ok) {
        console.log(await req.text());
        return setError("Could not check if gamepass exists, please retry.");
      }

      const data = (await req.json())["Data"];

      if (!data.hasGamePassWithCorrectPrice)
        return setError(
          `A gamepass set to ${robuxAmount} on the game ${gameName.substring(0, 20) + (gameName.length > 20 ? "..." : "")} was not found.`
        );

      return handleNext();
    } catch (error) {
      return setError("Could not check if gamepass exists, please retry.");
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <form>
        <h2 className="text-4xl font-semibold">Create Gamepass</h2>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="flex flex-col gap-10">
            <div>
              <p className="font-normal text-xl mb-3">
                Please create a Game Pass on{" "}
                <span className="text-main underline underline-offset-2 hover:text-main/75">
                  <a
                    href={`https://roblox.com/games/${gameId}`}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    {gameName.substring(0, 20) +
                      (gameName.length > 20 ? "..." : "")}
                  </a>
                </span>{" "}
                and set its price to{" "}
                <span className="text-main underline underline-offset-2">
                  {robuxAmount.toLocaleString()}
                </span>{" "}
                R$.
              </p>
              <p className="font-normal text-xl">
                When you're done, continue to the next step
              </p>
            </div>
            <a
              type="button"
              href={`https://create.roblox.com/dashboard/creations/experiences/${gameUniverseId}/monetization/passes`}
              target="_blank"
              rel="noreferrer"
              className="cta !py-3 w-80 hover:!bg-dark/75 text-xl text-center !bg-dark whitespace-nowrap"
            >
              Click to create gamepass
              <i className="pl-8 fas text-base fa-external-link"></i>
            </a>
            {error && <h2 className="text-lg text-red-500">{error}</h2>}
          </div>
          <div className="h-64">
            <iframe
              className="w-full h-full rounded-md shadow-md"
              src="https://www.youtube-nocookie.com/embed/GNGhVYEXNK0"
              title="How to Create a Gamepass on ROBLOX"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
        <div className="flex gap-4 justify-end mt-5">
          <button
            type="button"
            className="cta-cancel !py-2 w-32 text-xl text-center"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className="cta !py-2 w-32 text-xl text-center"
            onClick={checkGamepassThenContinue}
          >
            Next
          </button>
        </div>
      </form>
    </motion.div>
  );
}

export default Step3;
