import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import ChangePassword from "../ChangePassword";
import ChangeUsername from "../ChangeUsername";
import ChangeEmail from "../ChangeEmail";

const AccountSettings = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  return (
    <>
      <h2 className="text-4xl font-semibold">Account Settings</h2>
      <div className="mt-10 grid md:grid-cols-2">
        <div className="flex flex-col gap-5 col-span-2 lg:col-span-1">
          <div className="flex items-center justify-between text-xl">
            <div>
              <h3 className="opacity-50 text-base">Email</h3>
              <h3 className="font-medium">email@address.com</h3>
              <p className="font-normal pt-4">
                To request an email change, please open a ticket in the{" "}
                <a
                  href={`${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/discord`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline underline-offset-2 text-main hover:cursor-pointer hover:text-main/75 duration-100"
                >
                  discord
                </a>
              </p>
            </div>
          </div>
          <button
            className="cta !bg-dark-100 hover:!bg-dark-100/50 text-xl mt-5"
            onClick={() => (modalOpen ? close() : open())}
          >
            Change Password
          </button>
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait">
        {modalOpen && (
          <ChangePassword modalOpen={modalOpen} handleClose={close} />
        )}
      </AnimatePresence>
    </>
  );
};

export default AccountSettings;
